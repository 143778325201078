const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://d0vcp7808h.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://8yfmgxlonh.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://lu8aebanjf.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.1',
    HOSTNAME: 'https://locations.emeco.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.emeco.forwoodsafety.com',
    WEBSOCKET: 'wss://3eu94cgj67.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;